import { useEffect, useState } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { colors, spacing } from '~/utils/tailwindUtils';
import { Icon, IconName } from '~/components/core/Icon';
import { twMerge } from 'tailwind-merge';

type SelectOption = {
  id: string | number;
  value: string | number;
  label?: string;
};

type SimpleSelectProps = {
  options: SelectOption[];
  value: string | number | undefined;
  onChange: (newValue: string | null) => void;
  name?: string;
  placeholder: string;
  emptyLabel: string;
  selectClassName?: string;
  selectContainerClassName?: string;
  verified?: boolean;
  isDisabled?: boolean;
  loading?: boolean;
};
// **
// * SimpleSelect component that allows the user to select an option from a list
// * @param options
// * @param value
// * @param onChange
// * @param name
// * @param placeholder
// * @param emptyLabel
// * @param selectClassName
// * @returns SimpleSelect component

const SimpleSelect = ({
  options,
  value,
  onChange,
  name,
  placeholder,
  emptyLabel,
  selectClassName,
  selectContainerClassName,
  verified,
  isDisabled,
  loading,
}: SimpleSelectProps) => {
  const [label, setLabel] = useState<string | null>(
    options.find((option) => option.value === value)?.label || null
  );

  const handleChange = (newValue: string) => {
    onChange(newValue);
    const selectedOption = options.find((option) => option.value === newValue);
    setLabel(selectedOption?.label || null);
  };

  useEffect(() => {
    setLabel(options.find((option) => option.value === value)?.label || null);
  }, [value, options]);
  return (
    <Listbox name={name} value={value} onChange={handleChange}>
      <div className="relative">
        <ListboxButton
          id={name}
          className={twMerge(
            'relative w-full cursor-default rounded-md bg-gray-50 h-10 py-1.5 pl-3 pr-10 text-left text-gray-950 shadow-sm ring-inset ring-0 focus:outline-none focus:ring-1 focus:ring-gray-300 sm:text-sm sm:leading-6',
            isDisabled && 'hover:bg-gray-50 cursor-not-allowed',
            selectContainerClassName
          )}
          disabled={isDisabled}
        >
          {placeholder && !value && (
            <span className="block truncate text-gray-400">{placeholder}</span>
          )}
          {value && (
            <span className="block truncate font-semibold">{label}</span>
          )}
          <span
            className={twMerge(
              'pointer-events-none absolute inset-y-0 right-1 flex items-center pr-2',
              verified !== undefined && 'pr-10'
            )}
          >
            <Icon
              size={spacing[4]}
              name={IconName.ChevronDown}
              color={colors.gray[600]}
            />
          </span>
        </ListboxButton>

        <ListboxOptions
          transition
          className="absolute z-100 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
        >
          {options.length === 0 ? (
            <ListboxOption
              value={''}
              className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-gray-300 data-[focus]:text-white"
            >
              {emptyLabel}
            </ListboxOption>
          ) : (
            options.map((item) => (
              <ListboxOption
                key={item.id}
                value={item.value}
                className={twMerge(
                  'group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-gray-200 ',
                  selectClassName
                )}
              >
                <span className="block truncate font-normal group-data-[selected]:font-semibold">
                  {item.label}
                </span>
              </ListboxOption>
            ))
          )}
        </ListboxOptions>
        {!loading && typeof verified === 'boolean' ? (
          <Icon
            name={verified ? IconName.CheckCircle : IconName.CancelCircle}
            color={verified ? colors.green[500] : colors.red[500]}
            size={spacing[6]}
            className="absolute right-2 top-2 border-l border-gray-200 pl-2"
          />
        ) : loading ? (
          <Icon
            name={IconName.Loader}
            size={spacing[5]}
            color={colors.gray[500]}
            className="absolute right-2 top-3  animate-spin"
          />
        ) : null}
      </div>
    </Listbox>
  );
};

export default SimpleSelect;
