'use client';
import { useState, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import { Icon, IconName } from '~/components/core/Icon';
import { ColorModeEnum } from '~/utils/generalUtils';
import { colors, spacing } from '~/utils/tailwindUtils';
import Flex from '../flex/Flex';
import Text from '../texts/Text';
import { endOfWeek } from 'date-fns/endOfWeek';
import {
  getMonth,
  isBefore,
  isSameWeek,
  isSaturday,
  isSunday,
  subDays,
} from 'date-fns';
import { getWeekNumber, isSameOrAfter } from '~/utils/dateUtils';

type WeekpickerProps = {
  initialDate?: Date;
  firstAvailableDate?: Date;
  deliveryDate?: Date;
  onChange?: (date: Date) => void;
};

const now = new Date();

// Helper function to calculate the start of the week (Monday)
const getStartOfWeek = (date: Date) => {
  const day = date.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
  const diff = (day === 0 ? -6 : 1) - day; // Adjust so week starts on Monday
  const startOfWeek = new Date(date);
  startOfWeek.setDate(date.getDate() + diff);
  startOfWeek.setHours(0, 0, 0, 0);
  return startOfWeek;
};

const generateDays = (year: number, month: number) => {
  const days: Array<Date | null> = [];
  const date = new Date(year, month, 1);

  // Push days from the previous month
  const firstDayOfWeek = (date.getDay() || 7) - 1; // Adjust for ISO (Monday = 0)
  if (firstDayOfWeek > 0) {
    const prevMonth = month === 0 ? 11 : month - 1;
    const prevYear = month === 0 ? year - 1 : year;
    const prevMonthDays = new Date(prevYear, prevMonth + 1, 0).getDate(); // Get the total days in the previous month

    for (let i = firstDayOfWeek; i > 0; i--) {
      days.push(new Date(prevYear, prevMonth, prevMonthDays - i + 1)); // Add days from the previous month
    }
  }

  // Add actual days of the current month
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  // Push days from the next month
  const lastDayOfWeek = days.length % 7 === 0 ? 0 : 7 - (days.length % 7); // Fill up the remaining slots
  if (lastDayOfWeek > 0) {
    const nextMonth = month === 11 ? 0 : month + 1;
    const nextYear = month === 11 ? year + 1 : year;

    for (let i = 1; i <= lastDayOfWeek; i++) {
      days.push(new Date(nextYear, nextMonth, i)); // Add days from the next month
    }
  }

  return days;
};

// Helper function to get the month name
const getMonthName = (monthIndex: number): string => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return monthNames[monthIndex];
};

const getLastWorkingDay = (date: Date) => {
  // Get the end of the week (Sunday)
  const lastDayOfWeek = endOfWeek(date);

  // If it's Saturday or Sunday, subtract days to get to the last working day (Friday)
  if (isSaturday(lastDayOfWeek)) {
    return subDays(lastDayOfWeek, 1); // Subtract 1 day to get Friday
  }

  if (isSunday(lastDayOfWeek)) {
    return subDays(lastDayOfWeek, 2); // Subtract 2 days to get Friday
  }

  return lastDayOfWeek; // If it's a weekday (Monday to Friday), return as is
};

const getDateButtonStyles = (
  date: Date | null,
  hoveredDate: Date | null,
  selectedWeek: Date | null,
  selectedDate: Date | null,
  currentMonth: number,
  firstAvailableDate?: Date
) => {
  const baseStyles =
    'p-2 text-center rounded text-sm transition-colors ease-in-out duration-200 font-medium';
  const sameWeekHoverEffect =
    date && hoveredDate && isSameWeek(date, hoveredDate) && 'bg-red-100';
  const isfirstDayOfWeek =
    date &&
    hoveredDate &&
    date.getDay() === 1 &&
    isSameWeek(date, hoveredDate) &&
    'rounded-l-md';
  const isLastDayOfWeek =
    date &&
    hoveredDate &&
    date.getDay() === 5 &&
    isSameWeek(date, hoveredDate) &&
    'rounded-r-md';

  const selectedWeekStyles =
    date &&
    selectedWeek &&
    getStartOfWeek(date).toDateString() === selectedWeek.toDateString() &&
    !isBefore(date, now)
      ? 'bg-red-200 text-red-500'
      : '';

  const daysBeforeTodayStyles =
    date &&
    isBefore(date, now) &&
    'text-gray-300 hover:bg-transparent bg-transparent cursor-not-allowed no-pointer-events';
  const isOutsideCurrentMonth =
    date && getMonthName(getMonth(date)) !== getMonthName(currentMonth)
      ? 'text-gray-300 bg-transparent cursor-not-allowed no-pointer-events'
      : '';

  const selectedDateStyles =
    date && selectedDate?.toDateString() === date?.toDateString()
      ? 'bg-red-500 text-white rounded-none'
      : 'text-gray-900 hover:bg-red-100 rounded-none group-hover:bg-red-100  first:rounded-l-md last:rounded-r-md';

  const weekBoundaryStyles =
    date &&
    selectedWeek &&
    getStartOfWeek(date).toDateString() === selectedWeek.toDateString() &&
    !isBefore(date, now)
      ? date.getDay() === 1
        ? 'bg-red-500 hover:bg-red-500 text-white rounded-none rounded-l-md'
        : date.getDay() === 5
        ? 'bg-red-500 hover:bg-red-500 text-white rounded-none rounded-r-md'
        : 'bg-red-500 hover:bg-red-500 text-white rounded-none '
      : '';

  const disabledStyles =
    !date ||
    date.getDay() === 0 ||
    date.getDay() === 6 ||
    (firstAvailableDate && isSameOrAfter(date, firstAvailableDate))
      ? 'bg-transparent text-gray-300 cursor-not-allowed hover:bg-transparent'
      : '';

  return twMerge(
    baseStyles,
    sameWeekHoverEffect,
    selectedWeekStyles,
    selectedDateStyles,
    isOutsideCurrentMonth,
    weekBoundaryStyles,
    daysBeforeTodayStyles,
    disabledStyles,
    isfirstDayOfWeek,
    isLastDayOfWeek,
    ''
  );
};

/**
 * A functional component that renders a week picker calendar. It allows the user to select a week.
 * @param initialDate - The initial date to be selected
 * @param firstAvailableDate - The first available date to be selected
 * @return {JSX.Element} The week picker component
 */

const Weekpicker = ({
  initialDate,
  firstAvailableDate,
  deliveryDate,
  onChange,
}: WeekpickerProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    deliveryDate || null
  );
  const [hoveredDate, setHoveredDate] = useState<Date | null>(
    initialDate || null
  );

  const [selectedWeek, setSelectedWeek] = useState<Date | null>(
    deliveryDate ? getStartOfWeek(deliveryDate) : null
  );
  const [currentYear, setCurrentYear] = useState(
    new Date(deliveryDate || now).getFullYear()
  );
  const [currentMonth, setCurrentMonth] = useState(
    new Date(deliveryDate || now).getMonth()
  );
  const [, setEndOfWeek] = useState<Date | null>(null);
  const daysInMonth = generateDays(currentYear, currentMonth);

  const initialMonth = new Date().getMonth();
  const initialYear = new Date().getFullYear();

  // Handles when the user selects a date
  const handleDateSelect = useCallback(
    (date: Date) => {
      if (
        date.getDay() === 0 ||
        date.getDay() === 6 ||
        isBefore(date, now) ||
        (firstAvailableDate && isSameOrAfter(date, firstAvailableDate))
      ) {
        // Ignore Saturday (6) and Sunday (0)
        return;
      }
      const startOfWeek = getStartOfWeek(date);
      const endOfWeek = getLastWorkingDay(date);
      setSelectedDate(date);
      onChange && onChange(date);
      setSelectedWeek(startOfWeek);
      setEndOfWeek(endOfWeek);
    },
    [firstAvailableDate, onChange]
  );

  return (
    <Flex
      className="bg-white border border-gray-100 rounded-lg mt-2 p-4  w-full flex-col h-92"
      data-testid="week-picker"
    >
      {/* Calendar Header: month and year */}
      <Flex className="justify-between items-center mb-4">
        <Icon
          name={IconName.ChevronLeft}
          size={spacing[6]}
          color={
            currentMonth === initialMonth && currentYear === initialYear
              ? colors.gray[300]
              : colors.gray[900]
          }
          colorMode={ColorModeEnum.Fill}
          className={twMerge(
            'px-2 py-1 cursor-pointer',
            currentMonth === initialMonth &&
              currentYear === initialYear &&
              'cursor-default'
          )}
          data-testid="icon-chevron-left"
          onClick={() => {
            if (currentMonth === initialMonth && currentYear === initialYear) {
              return;
            }
            if (currentMonth === 0) {
              setCurrentMonth(11);
              setCurrentYear(currentYear - 1);
            } else {
              setCurrentMonth(currentMonth - 1);
            }
          }}
        />
        <div className="font-medium">
          {getMonthName(currentMonth)} {currentYear}
        </div>

        <Icon
          name={IconName.ChevronRight}
          size={spacing[6]}
          colorMode={ColorModeEnum.Fill}
          onClick={() => {
            if (currentMonth === 11) {
              setCurrentMonth(0);
              setCurrentYear(currentYear + 1);
            } else {
              setCurrentMonth(currentMonth + 1);
            }
          }}
          className="px-2 py-1 cursor-pointer"
          data-testid="icon-chevron-right"
        />
      </Flex>
      <Flex className="align-bottom gap-2">
        <Flex className="flex-col justify-around gap-2 border-r border-gray-75 pr-3 w-14">
          <Text className="text-center text-xs font-medium">CW</Text>
          {Array.from(
            new Set(
              daysInMonth
                .filter((date) => date)
                .map((date) => getWeekNumber(date!))
            )
          ).map((week, index) => (
            <Text
              key={index}
              className={twMerge(
                'text-center font-semibold p-2 rounded-md',
                selectedWeek &&
                  getWeekNumber(selectedWeek) === week &&
                  'bg-red-500 text-white',
                hoveredDate &&
                  getWeekNumber(new Date(hoveredDate || '')) === week &&
                  getWeekNumber(new Date(hoveredDate || '')) !==
                    getWeekNumber(selectedWeek || new Date()) &&
                  'bg-red-100'
              )}
            >
              {week}
            </Text>
          ))}
        </Flex>
        {/* Calendar Grid */}
        <div className="grid grid-cols-7 gap-y-2 w-full">
          {['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'].map((day, index) => (
            <Text key={index} className="text-center text-sm text-gray-500">
              {day}
            </Text>
          ))}

          {daysInMonth.map((date: Date | null, index) => (
            <button
              key={index}
              type="button"
              onClick={() => date && handleDateSelect(date)}
              onMouseEnter={() => date && setHoveredDate(date)}
              onMouseLeave={() => setHoveredDate(null)}
              className={twMerge(
                getDateButtonStyles(
                  date,
                  hoveredDate,
                  selectedWeek,
                  selectedDate,
                  currentMonth,
                  firstAvailableDate
                )
              )}
              disabled={!date || date.getDay() === 0 || date.getDay() === 6}
            >
              {date ? date.getDate() : ''}
            </button>
          ))}
        </div>
      </Flex>
    </Flex>
  );
};

export default Weekpicker;
