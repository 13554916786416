import { decodeJwt } from 'jose';
import { User } from 'next-auth';
import { MagicLinkActionEnum } from './magicLinkUtils';
import { AuthResponseType } from '~/types-and-enums/authTypes';

export async function getSessionFromAuth(
  response: Response
): Promise<User | null> {
  const data: AuthResponseType = await response.json();

  if (!response.ok || response.status >= 400) {
    const message = response.statusText;
    throw new Error(
      `${response.status} : ${message ? message : JSON.stringify(data)}`
    );
  }

  if (response.ok && data) {
    const claims = decodeJwt(data.access_token);
    const finishedSetup =
      claims.finished_setup !== undefined
        ? Boolean(claims.finished_setup)
        : undefined;

    return {
      id: claims.user_id as string,
      name: claims.sub as string,
      email: claims.sub as string,
      access_token: data.access_token,
      refresh_token: data.access_token,
      expires: claims.exp as number,
      finished_setup: finishedSetup,
      action: data.action as MagicLinkActionEnum,
      redirect: data.redirect,
    };
  }

  return null;
}

export async function getImpersonationSession(token?: string) {
  if (token) {
    const claims = decodeJwt(token);
    return {
      id: claims.user_id as string,
      name: claims.sub as string,
      email: claims.sub as string,
      access_token: token,
      refresh_token: token,
      expires: claims.exp as number,
    };
  }

  return null;
}

export enum AuthErrorTypeEnum {
  DEACTIVATED,
  INVALID_CREDENTIALS,
}

export const getAuthErrorType = (error: string): AuthErrorTypeEnum => {
  if (error.includes('401')) {
    return AuthErrorTypeEnum.INVALID_CREDENTIALS;
  }
  if (error.includes('403')) {
    return AuthErrorTypeEnum.DEACTIVATED;
  }
  return AuthErrorTypeEnum.INVALID_CREDENTIALS;
};

export type PasswordSetupTranslationKeyType = {
  title: string;
  description: string;
  label: string;
  placeholder: string;
  confirmLabel: string;
  confirmPlaceholder: string;
  errorLabel: string;
  errorConfirmLabel: string;
  submitButtonLabel: string;
};
