import { formatISO, fromUnixTime } from 'date-fns';
import type { NextAuthOptions } from 'next-auth';
import CredentialsProvider from 'next-auth/providers/credentials';

import { ROUTES_MAP } from '~/constants/routes';
import { API_HOST } from '~/constants';

import { getImpersonationSession, getSessionFromAuth } from '~/utils/authUtils';
import { MagicLinkActionEnum } from '~/utils/magicLinkUtils';

const PATHS_MAP = {
  authLogin: '/auth/login',
  authReset: '/auth/reset-password',
  authForgotPassword: '/auth/forgot-password',
};

declare module 'next-auth' {
  interface User {
    access_token: string;
    refresh_token: string;
    expires: number;
    finished_setup?: boolean;
    action?: MagicLinkActionEnum;
    redirect?: string;
  }

  interface Session {
    user: {
      id: string;
      name: string;
      email: string;
      access_token: string;
      refresh_token: string;
      finished_setup?: boolean;
      action?: MagicLinkActionEnum;
      redirect?: string;
      expires: number;
    };
  }
}

export const authOptions: NextAuthOptions = {
  secret: process.env.NEXTAUTH_SECRET,
  session: {
    maxAge: 1 * 24 * 60 * 60, // 1 day
  },
  providers: [
    CredentialsProvider({
      id: 'credentials',
      type: 'credentials',
      credentials: {
        email: {
          label: 'email',
          type: 'email',
          placeholder: '',
        },
        password: { label: 'Password', type: 'password' },
      },
      async authorize(credentials) {
        if (!credentials) {
          return null;
        }

        const payload = {
          username: credentials.email,
          password: credentials.password,
        };

        const res = await fetch(`${API_HOST}${PATHS_MAP.authLogin}/`, {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        return getSessionFromAuth(res);
      },
    }),
    CredentialsProvider({
      id: 'create-password',
      type: 'credentials',
      credentials: {
        password: { label: 'Password', type: 'password' },
        token: { label: 'Token', type: 'password' },
      },
      async authorize(credentials) {
        if (!credentials) {
          return null;
        }

        const payload = {
          password: credentials.password,
          token: credentials.token,
        };

        const res = await fetch(`${API_HOST}/auth/reset-password/`, {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        return getSessionFromAuth(res);
      },
    }),
    CredentialsProvider({
      id: 'magic-link',
      type: 'credentials',
      credentials: {
        token: { label: 'token', type: 'text' },
      },
      async authorize(credentials) {
        if (!credentials) {
          return null;
        }

        const payload = {
          token: credentials.token,
        };
        const res = await fetch(`${API_HOST}/auth/validate-magic-token`, {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
        });

        return getSessionFromAuth(res);
      },
    }),
    CredentialsProvider({
      id: 'impersonation',
      type: 'credentials',
      credentials: {
        token: { label: 'token', type: 'text' },
      },

      async authorize(credentials) {
        if (!credentials || !credentials.token) {
          return null;
        }

        return getImpersonationSession(credentials.token);
      },
    }),
  ],
  pages: {
    signIn: ROUTES_MAP.authLogin,
  },
  callbacks: {
    async jwt({ token, user }) {
      if (user) {
        return {
          ...token,
          id: user.id,
          access_token: user.access_token,
          refresh_token: user.access_token,
          expires: user.expires,
          finished_setup: user.finished_setup ?? undefined,
          action: user.action,
          redirect: user.redirect,
        };
      }
      return token;
    },

    async session({ session, token }) {
      session.user.id = token.id as string;
      session.user.name = token.name as string;
      session.user.email = token.email as string;
      session.user.access_token = token.access_token as string;
      session.user.refresh_token = token.refresh_token as string;
      session.user.finished_setup = token.finished_setup as boolean | undefined;
      session.user.action = token.action as MagicLinkActionEnum;
      session.user.redirect = token.redirect as string;
      session.user.expires = token.expires as number;

      if (token.expires) {
        session.expires = formatISO(fromUnixTime(token.expires as number));
      }
      return session;
    },
  },
};
