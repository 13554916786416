import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { NextAuthOptions, Session } from 'next-auth';
import { Suggestion } from '~/app/[locale]/home/store/_components/Search';
import { SearchResponse } from '~/app/[locale]/home/store/requests';
import { authOptions } from '~/app/api/auth/[...nextauth]/constants';
import { USER_SETTINGS_EMPTY } from '~/constants';
import {
  AcceptedCookiePolicyEnum,
  AcceptedCookiePolicyType,
} from '~/types-and-enums/cookieTypes';
import { DeliveryDetailsType } from '~/types-and-enums/deliveryTypes';
import { EmptyObjectType } from '~/types-and-enums/generalTypes';
import {
  UserDelievryAddressType,
  UserSettingsType,
} from '~/types-and-enums/settingsTypes';
import { ShoppingCartOrderType } from '~/types-and-enums/storeTypes';

export const cookieModalAtom = atom<boolean>(false);

export const acceptedCookiePolicyAtom = atom<AcceptedCookiePolicyType>(
  AcceptedCookiePolicyEnum.None
);

export const wantsStoreIsLiveNotification = atom<boolean>(false);

export const openOffersCountAtom = atom<number>(0);
export const openDarkBackgroundAtom = atom<boolean>(false);

export const isSearchActiveAtom = atom<boolean>(false);
export const isLoadingAtom = atom<boolean>(false);

export const searchValueAtom = atom<string>('');
export const searchSuggestionAtom = atom<Suggestion[] | null>(null);
export const shippingAddressAtom = atom<
  | ({
      validationObject?: {
        street: boolean;
        city: boolean;
        postal_code: boolean;
        country: boolean;
        route?: boolean;
        locality?: boolean;
        point_of_interest?: boolean;
      };
      isAddressValid?: boolean;
    } & UserDelievryAddressType)
  | EmptyObjectType
>({});

export const userSettingsAtom = atom<UserSettingsType>(USER_SETTINGS_EMPTY);
export const isMobileAtom = atom<boolean>(false);
export const userSessionAtom = atom<Session | NextAuthOptions>(authOptions);

export const loadingCart = atom({
  loading: false,
  id: '',
});
export const cartDetailsState = atom<ShoppingCartOrderType | null>(null);

export const searchResponseAtom = atomWithStorage<SearchResponse | null>(
  'searchResponse',
  null
);

export const similarFEsInDeliveryDetailsPage = atom<DeliveryDetailsType[]>([]);

export type Filters = {
  category?: string;
  priceRange?: [number, number];
  brand?: string;
  deliveryTime?: string;
};

export const filtersAtom = atom<Filters>({});

export const numberOfDisplayedComments = atom<number>(0);
