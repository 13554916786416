import {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
  useTransition,
} from 'react';
import * as yup from 'yup';
import { isObjectsDeepEqual } from '~/utils/generalUtils';
import { validateFormObject } from '~/utils/validationUtils';

/**
 * useFormServerAction is a custom hook that handles the form submission and validation logic.
 * It takes an action function that is called when the form is submitted, and an optional schema object that is used to validate the form data.
 * The hook returns a formAction function that can be called with the form data to initiate the form submission.
 * The formAction function returns a promise that resolves when the action is finished.
 * The hook also returns the form state, loading state, and any validation errors that occur during the form submission.
 * The hook uses React's useTransition hook to handle the loading state and to ensure that the form state is updated only after the action is finished.
 * you can check here for more information: https://github.com/vercel/next.js/discussions/51371#discussioncomment-6773023
 * @param action - The action function that is called when the form is submitted.
 * @param onFinish - An optional callback function that is called when the action is finished.
 * @param initialFormData - An optional object that contains the initial form data.
 * @param schema - An optional schema object that is used to validate the form data.
 * @returns formAction - A function that can be called with the form data to initiate the form submission.
 * @returns isLoading - A boolean that indicates whether the form is currently submitting.
 * @returns formData - The current form data.
 * @returns setFormData - A function that can be called to update the form data.
 * @returns resetForm - A function that can be called to reset the form data to its initial state.
 * @returns isFormChanged - A boolean that indicates whether the form data has changed since the last submission.
 * @returns isActionFullfilled - A boolean that indicates whether the action is fullfilled.
 * @returns formState - The current form state.
 * @returns errors - An object that contains any validation errors that occur during the form submission.
 * @returns setErrors - A function that can be called to update the validation errors.
 */

type ServerActionType<T, R> = {
  action: (formData: T) => Promise<R>;
  initialFormData: object;
  schema?: yup.ObjectSchema<{}>;
  onFinish?: () => void;
  customFieldSchemaChecker?: () => object;
};

const useFormServerAction = <T, R = void>({
  action,
  initialFormData = {},
  schema,
  onFinish,
  customFieldSchemaChecker,
}: ServerActionType<T, R>): {
  formAction: (formData: FormData) => Promise<R | undefined>;
  isLoading: boolean;
  errors: Record<string, string>;
  setErrors: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  setFormData: React.Dispatch<Partial<T>>;
  formData: T;
  formState: R;
  resultData: R;
  resetForm: () => void;
  isFormChanged: boolean;
} => {
  const [isLoading, startTransition] = useTransition();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [formState, setFormState] = useState<R>(initialFormData as R);
  const [resultData, setResultData] = useState<R>(initialFormData as R);
  const [finished, setFinished] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isActionFullfilled, setIsActionFullfilled] = useState(false);
  const resolver = useRef<(value?: R | PromiseLike<R>) => void>();

  // useReducer is used to manage the form data state and update the form data when the form is changing before submission
  const [formData, setFormData] = useReducer(
    (state: T, newState: Partial<T>) => {
      setErrors((prev) => ({ ...prev, [Object.keys(newState)[0]]: '' }));
      return {
        ...state,
        ...newState,
      };
    },
    initialFormData as T
  );

  useEffect(() => {
    if (!finished) return;
    if (onFinish && isActionFullfilled) {
      onFinish();
    }
    resolver.current?.(formState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, finished, isActionFullfilled]);

  const resetForm = useCallback(() => {
    setFormData(formState as unknown as Partial<T>);
    setErrors({});
  }, [formState]);

  useEffect(() => {
    const checker = isObjectsDeepEqual(formData as {}, formState as {});
    setIsFormChanged(!checker);
  }, [formData, formState]);

  const formAction = async (data: FormData): Promise<R | undefined> => {
    // Validate the form data using the schema object if provided and set the errors if any validation errors occur during the form submission
    if (schema) {
      const { isValid, validationErrors } = await validateFormObject(
        schema,
        formData as unknown as Record<string, string>
      );
      if (!isValid) {
        setErrors(validationErrors);
        return;
      }
    }

    if (customFieldSchemaChecker) {
      const isValid = customFieldSchemaChecker();
      if (typeof isValid === 'object' && Object.keys(isValid).length > 0) {
        setErrors(isValid as Record<string, string>);
        return;
      }
    }

    // If the form is valid, initiate the async action and handle the response accordingly in server side
    startTransition(() => {
      setIsActionFullfilled(false);
      try {
        const formDataObj = new FormData();
        Object.entries(formData as unknown as Record<string, string>).forEach(
          ([key, value]) => {
            formDataObj.append(key, value);
          }
        );
        action(formData as T).then((newData) => {
          if (newData && typeof newData === 'object' && 'errors' in newData) {
            setErrors(newData.errors as Record<string, string>);

            setFinished(true);
            resolver.current?.(formState);
            return;
          }
          setFormState(formData as unknown as R);
          setResultData(newData as R);
          setFinished(true);
          setIsActionFullfilled(true);
          setErrors({});
        });
      } catch (error) {
        setErrors(error as Record<string, string>);
      }
    });

    // Return a promise that resolves when the action is finished
    return new Promise((resolve) => {
      resolver.current = resolve;
    });
  };

  return {
    formAction,
    isLoading,
    errors,
    setErrors,
    setFormData,
    formData,
    resetForm,
    isFormChanged,
    formState,
    resultData,
  };
};

export default useFormServerAction;
