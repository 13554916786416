export const QUERY_PARAM_SEARCH_BUTTON_CLICKED = 'search-button-clicked';
export const QUERY_PARAM_WELCOME = 'welcome';
export const QUERY_PARAM_SEARCH = 'search';
export const QUERY_SELECTED_STATUSES = 'selected-statuses';
export const OFFER_SHOULD_REFRESH = 'offer-should-refresh';
export const OFFER_IS_LOADING = 'offer-is-loading';
export const OFFER_IS_REJECTED = 'offer-status=Denied';
export const OFFER_IS_ACCEPTED = 'offer-status=Accepted';
export const CART_SHOULD_BE_REFRESH = 'cart-should-refresh';
export const CART_PRICE_HAS_CHANGED = 'cart-price-has-changed';
export const IMPERSONATING_ACCOUNT = 'impersonating_account';
export const USER_FIRST_NAME = 'user_firstName';
export const USER_LAST_NAME = 'user_lastName';
export const CHANGES_SAVED = 'changes-saved';
export const ERROR_HAS_HAPPENED = 'error-has-happened';
export const HAS_SEEN_MODAL = 'hasSeenModal';
export const QUERY_PARAM_FULFILLMENT_EVENT_ID = 'fulfillment_event_id';
export const QUERY_PARAM_CATEGORY = 'category';
export const OFFER_HAS_UPDATED_MODAL = 'offer-has-updated-modal';
export const IS_WEEK_PICKER_MODAL_OPEN = 'isWeekPickerModalOpen';
export const IS_CHANGE_ADDRESS_MODAL_OPEN = 'isChangeAddressModalOpen';
export const QUERY_PARAM_FINANCING_PLAN = 'selected-fp'; // selected financing plan
export const QUERY_PARAM_FINANCING_PLAN_AMOUNT = 'selected-fpa'; // selected financing plan amount
export const QUERY_PARAM_VIEW_SUMMARY = 'view-summary';
export const QUERY_PARAM_SHOW_MORE_DETAILS = 'showMoreDetails';
export const QUERY_PARAM_CONFIRM_ORDER_PLACE_FAILED = 'COP-failed'; // Confirm Order Place failed
export const QUERY_PARAM_CONFIRM_ORDER_PLACE_CLICKED = 'COP-clicked'; // Confirm Order Place clicked
export const SEARCH_PARAM_SELECTED_UNIT = 'unit';
export const SEARCH_PARAM_SELECTED_QUANTITY = 'quantity';
export const SEARCH_PARAM_SELECTED_WEEK = 'week';
export const SEARCH_PARAM_ERROR_TEXT = 'error-text';
export const SEARCH_PARAM_CART_UPDATED = 'cart-updated';
