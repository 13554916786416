import { API_HOST } from '~/constants';

export const API_ENDPOINT_NAMES = {
  quote: 'quote',
  order: 'order',
  fulfillmentEvent: 'fulfillment_event',
} as const;

const ENDPOINT = {
  me: '/me',
  preferences: '/me/preferences',
  quotes: '/quotes',
  quotesRequest: '/quote-requests',
  invoices: '/customer-invoices',
  recommendedProducts: '/inventory/products/recommended',
  fulfillmentEvents: '/fulfillment-events',
  owners: '/owners',
  orders: '/orders',
  search: '/search', // endpoint to search for offers(quotes)/orders/deliveries(fullfilment-events)
  inventory: '/inventory',
  claims: '/v2/claims',
  notifications: '/notifications',
  auth: '/auth',
  authLogin: '/auth/login',
  authAdminLogin: '/auth/admin-login',
  updatePreferences: '/me/preferences',
  updateDeliveryAddress: '/me/update-delivery-address',
  updateDeliveryContact: '/me/update-delivery-contact',
  updateDeliveryInfo: '/me/update-delivery-info',
  updatePassword: '/auth/update-password',
  updateBillingAddress: '/me/update-billing-address',
  documents: '/documents',
  // Shop endpoints
  shopProductDetails: '/shop/inventory/products',
  cart: '/shop/cart',
  cartItem: '/shop/cart/item',
  checkout: '/shop/checkout',
  checkoutPlaceOrder: '/shop/place-order',
  bnplOptions: '/shop/bnpl/options',
  validateAdrress: '/address/validate',
  priceEngine: '/inventory/products/price/quote',
  // Cart shipping address
  shippingAddress: '/shop/cart/shipping-address',
  // Price engine
  priceEngineDynamic: '/shop/inventory/products/price/dynamic', // POST request to get the dynamic price
} as const;

type EndpointKeys = keyof typeof ENDPOINT;

/**
 * API endpoints
 * - me: /me
 * - preferences: /me/preferences
 * - quotes: /quotes
 * - quotesRequest: '/quote-requests',
 * - invoices: /customer-invoices
 * - recommendedProducts: /inventory/products/recommended
 * - fulfillmentEvents: /fulfillment-events
 * - owners: /owners
 * - orders: /orders
 * - search: /search
 * - inventory: /inventory
 * - claims: /v2/claims
 * - notifications: /notifications
 * - updateDeliveryAddress: /me/update-delivery-address
 * - updateDeliveryContact: /me/update-delivery-contact
 * - updateDeliveryInfo: /me/update-delivery-info
 * - updatePassword: /auth/update-password
 * - updateBillingAddress: /me/update-billing-address
 * - documents: /documents
 * @example API_ENDPOINT.me
 */
export const API_ENDPOINT = (Object.keys(ENDPOINT) as EndpointKeys[]).reduce(
  (acc, key) => {
    acc[key] = `${API_HOST}${ENDPOINT[key]}`;
    return acc;
  },
  {} as Record<EndpointKeys, string>
);

export const getOfferPdfEndpoint = (id: string) =>
  `${API_ENDPOINT.quotes}/${id}/offer-pdf`;

export const getBillingDocumentLink = (id: string) =>
  `/api/home/billing/${id}/documents`;

export const AndercoreExternalLinks = {
  de: {
    privacy: 'https://andercore.com/de/datenschutz/',
    imprint: 'https://andercore.com/de/impressum/',
    terms: 'https://andercore.com/de/agb/',
    requestQuote: 'https://andercore.com/de/angebot-anfragen/',
  },
  en: {
    privacy: 'https://andercore.com/en/privacy-policy/',
    imprint: 'https://andercore.com/en/imprint/',
    terms: 'https://andercore.com/en/terms/',
    requestQuote: 'https://andercore.com/en/request-quote/',
  },
  fr: {
    privacy: 'https://andercore.com/fr/politique-de-confidencialite/',
    imprint: 'https://andercore.com/fr/mentions-legales/',
    terms: 'https://andercore.com/fr/cgv/',
    requestQuote: 'https://andercore.com/fr/demande-devis/',
  },
  it: {
    privacy: 'https://andercore.com/it/politique-de-confidencialite/',
    imprint: 'https://andercore.com/it/mentions-legales/',
    terms: 'https://andercore.com/it/cgv/',
    requestQuote: 'https://andercore.com/it/richiedi-preventivo/',
  },
  es: {
    privacy: 'https://andercore.com/es/politique-de-confidencialite/',
    imprint: 'https://andercore.com/es/mentions-legales/',
    terms: 'https://andercore.com/es/cgv/',
    requestQuote: 'https://andercore.com/es/solicitar-presupuesto/',
  },
};
