'use client';

import { useCallback, useRef, useState } from 'react';
import Flex from '../flex/Flex';
import { twMerge } from 'tailwind-merge';

export type SwipeUpDownProps = {
  handleToggle: () => void;
  toggleTriggerVariable: boolean;
  children: React.ReactNode;
  className?: string;
};

/**
 * Swipe up down component that allows the user to swipe up or down to toggle the display of a component
 * @param handleToggle - function to call when the user swipes up or down
 * @param toggleTriggerVariable - variable using which the component is toggled. If true, the component is displayed. If false, the component is hidden
 * @param children - children to display
 * @param className - additional classes to add to the component
 */
const SwipeUpDown = ({
  handleToggle,
  toggleTriggerVariable,
  children,
  className = '',
}: SwipeUpDownProps) => {
  const [startY, setStartY] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  // Prevent default touch move to avoid scrolling while dragging
  const preventDefaultTouchMove = useCallback((e: TouchEvent) => {
    e.preventDefault();
  }, []);

  // Handle touch start event to start dragging
  const handleTouchStart = useCallback(
    (e: React.TouchEvent) => {
      setStartY(e.touches[0].clientY);
      setIsDragging(true);
      document.addEventListener('touchmove', preventDefaultTouchMove, {
        passive: false,
      });
    },
    [preventDefaultTouchMove]
  );

  // Handle touch move event to move the component while dragging
  const handleTouchMove = useCallback(
    (e: React.TouchEvent) => {
      if (isDragging && startY !== null) {
        const moveY = e.touches[0].clientY;
        if (containerRef.current) {
          const deltaY = moveY - startY;
          containerRef.current.style.transform = `translateY(${deltaY}px)`;
          const openMoreDetails = deltaY < -5 && !toggleTriggerVariable;
          const closeMoreDetails = deltaY > 5 && toggleTriggerVariable;
          if (openMoreDetails || closeMoreDetails) {
            // Threshold for toggling
            handleToggle();
            setIsDragging(false);
            setStartY(null);
          }
        }
      }
    },
    [handleToggle, isDragging, toggleTriggerVariable, startY]
  );

  // Handle touch end event to stop dragging
  const handleTouchEnd = useCallback(() => {
    if (containerRef.current) {
      containerRef.current.style.transform = 'translateY(0)';
    }
    setIsDragging(false);
    // Remove preventDefaultTouchMove as dragging is complete
    document.removeEventListener('touchmove', preventDefaultTouchMove);
  }, [preventDefaultTouchMove]);

  // Add transition class when not dragging stops to animate the component
  const transitionClass = isDragging
    ? 'transition-none'
    : 'transition-transform duration-100 ease';

  return (
    <Flex
      className={twMerge('flex-col', transitionClass, className)}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      ref={containerRef}
    >
      {children}
    </Flex>
  );
};

export default SwipeUpDown;
