'use client';

import { useCallback, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Icon, IconName } from '~/components/core/Icon';
import Text from '~/components/customComponents/texts/Text';
import { ColorModeEnum } from '~/utils/generalUtils';
import { colors, spacing } from '~/utils/tailwindUtils';
import Flex from '../flex/Flex';

export interface FormFieldProps {
  value: string;
  onChange?: (value: any) => void;
  name?: string;
  classNames?: string;
  multipier?: number;
  isDisbled?: boolean;
  showWarning?: boolean;
}

/**
 * Counter component that is used to display a counter with a plus and minus button to increase and decrease the counter value respectively
 * @param name - The name of the counter
 * @param onChange - The function to call when the counter value is changed
 * @param value - The value of the counter
 * @param classNames - The classes to be applied to the counter
 * @param multipier - The multiplier to be used when increasing or decreasing the counter value
 * @returns Counter component
 */
export const Counter = ({
  name,
  onChange = () => {},
  value,
  classNames,
  multipier,
  isDisbled = false,
  showWarning,
}: Omit<FormFieldProps, 'label'>) => {
  const [inputValue, setInputValue] = useState<number>(Number(value));
  const multiplierOr1 = multipier ? multipier : 1;
  const hasMultipier = Boolean(multipier);

  useEffect(() => {
    // Update the input value only when the value prop changes
    if (Number(value) !== inputValue) setInputValue(Number(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    !isDisbled && onChange(inputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const handleOnChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = Number(evt.target.value);
      !isDisbled && setInputValue(newValue >= 1 ? newValue : 1);
    },
    [isDisbled]
  );

  const handleIncrease = useCallback(() => {
    !isDisbled && setInputValue((prev: number) => prev + multiplierOr1); // Increase the value by the multiplier or 1
  }, [multiplierOr1, isDisbled]);

  const handleDecrease = useCallback(() => {
    !isDisbled &&
      setInputValue(
        (prev: number) =>
          prev - multiplierOr1 >= 1 ? prev - multiplierOr1 : multiplierOr1 // If the value is less than 1 (or multiplier), set it to 1 (or multiplier)
      );
  }, [multiplierOr1, isDisbled]);

  return (
    <Flex className="bg-gray-50 gap-2 rounded-full px-4 py-2">
      {showWarning && (
        <Flex className="items-center">
          <Icon name={IconName.AlertTriangle} color={colors.orange[500]} />
        </Flex>
      )}
      <Flex
        className={twMerge(
          'cursor-pointer flex-1 items-center gap-1',
          inputValue === multiplierOr1 && 'opacity-50 cursor-not-allowed'
        )}
        onClick={handleDecrease}
        data-testid="decrease-button"
      >
        <Icon
          name={IconName.Minus}
          width={spacing[hasMultipier ? 2 : 3]}
          height={spacing[4]}
          color={colors.gray[500]}
          customStrokeWidth={6}
          colorMode={ColorModeEnum.Fill}
          className="pt-px"
        />
        <Text className="text-gray-500 text-2xs md:text-xs font-medium">
          {multipier}
        </Text>
      </Flex>
      <input
        name={name}
        id={name}
        type="number"
        value={inputValue}
        onChange={handleOnChange}
        className={twMerge(
          'text-black text-center text-sm md:text-base font-medium w-full focus:outline-none flex-6 bg-gray-50',
          classNames
        )}
        disabled={hasMultipier}
      />
      <Flex
        className="cursor-pointer flex-1 justify-end items-center gap-1"
        onClick={handleIncrease}
        data-testid="increase-button"
      >
        <Icon
          name={IconName.Add}
          size={spacing[hasMultipier ? 3 : 4]}
          color={colors.gray[500]}
          colorMode={ColorModeEnum.Fill}
          customStrokeWidth={3}
        />
        <Text className="text-gray-500 text-2xs md:text-xs font-medium">
          {multipier}
        </Text>
      </Flex>
    </Flex>
  );
};
