import {
  addDays,
  endOfWeek,
  format,
  getWeek,
  isBefore,
  isSaturday,
  isSunday,
  Locale,
  min,
  parse,
  parseISO,
  startOfDay,
  startOfWeek,
  subDays,
} from 'date-fns';
import { enGB, de, fr, es, it } from 'date-fns/locale';
import { DD_MM_YYYY } from '~/constants';
import { LocaleEnum } from '~/locales/resources';

/**
 * Parses a date range string and returns an array of Date objects.
 *
 * @param {string} dateRange - The date range string to parse, eg: 01.01.2023 — 05.01.2023
 * @return {Date[]} An array of Date objects representing the parsed dates.
 */
export const parseDateRangeStringToDates = (dateRange: string): Date[] => {
  if (!dateRange) return [];

  const dates: Date[] = dateRange
    .split(' — ')
    .map((date) => parse(date, DD_MM_YYYY, new Date()))
    .filter((date) => !isNaN(date.getTime()));

  if (dates.length === 0) return [];

  return dates.length === 2 ? dates : [dates[0], dates[0]];
};

/**
 * Parses an array of Date objects into a formatted date range string.
 *
 * @param {Date[]} dates - The array of Date objects to parse.
 * @return {string} The formatted date range string, or an empty string if the input is invalid.
 * @throws {Error} If an invalid date is provided.
 */
export const parseDatesToDateRangeString = (dates: Date[]): string => {
  if (!dates || dates.length === 0) return '';

  return dates
    .map((date) => {
      if (!(date instanceof Date) || isNaN(date.getTime())) {
        throw new Error('Invalid date provided');
      }
      return formatDate(date, LocaleEnum.DE);
    })
    .join(' — ');
};

/**
 * Check if today's date has passed the given payment due date.
 * @param paymentDueDate - The payment due date in the format "YYYY-MM-DD".
 * @returns true if today has passed the payment due date, otherwise false.
 */
export const hasPassedPaymentDueDate = (
  paymentDueDate: string | Date
): boolean => {
  const today = new Date();
  const dueDate = new Date(paymentDueDate);

  // Set the time of today and dueDate to the start of the day for accurate comparison
  today.setHours(0, 0, 0, 0);
  dueDate.setHours(0, 0, 0, 0);

  return today > dueDate;
};

/**
 * Check if the given date is in the past.
 * @param date - The date to check.
 * @returns true if the date is in the past, otherwise false.
 */
export const compareDateTime = (
  date1: Date | string,
  date2: Date | string
): boolean => {
  if (!date1 || !date2) return false;
  let date1Obj = typeof date1 === 'string' ? new Date(date1) : date1;
  let date2Obj = typeof date2 === 'string' ? new Date(date2) : date2;
  return date1Obj > date2Obj; // Compare current date > given date
};

/**
 * Formats a date object or string into a formatted date string.
 * @param date - The date object or string to format.
 * @param locale - The locale to use for formatting. Default is DE.
 * @param dateFormat - The date format to use. Default is DD_MM_YYYY.
 * @returns
 */
export const formatDate = (
  date: Date | string,
  locale?: LocaleEnum,
  dateFormat: string = DD_MM_YYYY
): string => {
  if (!date) return '';
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  dateObj.setHours(dateObj.getHours() - dateObj.getTimezoneOffset() / 60);
  const localeMap = {
    [LocaleEnum.EN]: enGB,
    [LocaleEnum.DE]: de,
    [LocaleEnum.FR]: fr,
    [LocaleEnum.IT]: it,
    [LocaleEnum.ES]: es,
  } as { [key in LocaleEnum]: Locale };
  return format(date, dateFormat, {
    locale: localeMap[locale ?? LocaleEnum.DE],
  });
};

/**
 * Returns the formatted delivery period string based on the start and end dates.
 * If the years of the start and end dates are the same, only the end date's year is shown.
 * Otherwise, both the start and end dates include their years.
 *
 * @param startDate - The planned delivery window start date.
 * @param endDate - The planned delivery window end date.
 * @param locale - The locale to use for formatting.
 * @returns The formatted delivery period string.
 */
export const getFormattedDeliveryPeriod = (
  startDate?: Date | string,
  endDate?: Date | string,
  locale: LocaleEnum = LocaleEnum.DE
): string => {
  if (!startDate || !endDate) return '';

  const startYear = new Date(startDate).getFullYear();
  const endYear = new Date(endDate).getFullYear();

  // If start year and end year are the same, only display the year once
  if (startYear === endYear) {
    return (
      formatDate(startDate, locale, 'dd.MM') +
      ' ~ ' +
      formatDate(endDate, locale, 'dd.MM.yyyy')
    );
  }

  // If start year and end year are different, display both years
  return (
    formatDate(startDate, locale, 'dd.MM.yyyy') +
    ' ~ ' +
    formatDate(endDate, locale, 'dd.MM.yyyy')
  );
};

export const getFirstWorkingDayOfWeek = (date: Date): Date => {
  let firstDay = startOfWeek(date); // Monday
  if (isSaturday(firstDay)) {
    firstDay = addDays(firstDay, 2); // Skip to Monday
  } else if (isSunday(firstDay)) {
    firstDay = addDays(firstDay, 1); // Skip to Monday
  }
  return firstDay;
};
export const getLastWorkingDayOfWeek = (date: Date): Date => {
  let lastDay = endOfWeek(date, { weekStartsOn: 1 }); // Sunday
  if (isSaturday(lastDay)) {
    lastDay = subDays(lastDay, 2); // Skip to Friday
  } else if (isSunday(lastDay)) {
    lastDay = subDays(lastDay, 3); // Skip to Friday
  }
  return lastDay;
};

export const isSameOrAfter = (firstDate: Date, secondDate: Date): boolean => {
  const normalizedDate1 = startOfDay(firstDate);
  const normalizedDate2 = startOfDay(secondDate);

  return isBefore(normalizedDate1, normalizedDate2);
};

// Helper function to calculate ISO week number
export const getWeekNumber = (date: Date) => {
  return getWeek(date, {
    weekStartsOn: 1,
    firstWeekContainsDate: 4,
  });
};

export const findEarliestDate = (dates: string[]) => {
  const parsedDates = dates.map((date) => parseISO(date)); // Convert strings to Date objects
  const earliestDate = min(parsedDates); // Find the earliest date
  return earliestDate.toISOString(); // Convert back to ISO string
};
