export const ROUTE_SEGMENT = {
  quotes: 'quotes',
  offers: 'offers',
  orders: 'orders',
  billing: 'billing',
  support: 'support',
  settings: 'settings',
  deliveryInformation: 'delivery-information',
  account: 'account',
  notifications: 'notifications',
  store: 'store',
  myOrders: 'my-orders',
  offersPending: 'offers-pending',
  orderInProgress: 'order-in-progress',
  deliveryInProgress: 'delivery-in-progress',
  ordersPlaced: 'orders-placed',
  ordersInDelivery: 'orders-in-delivery',
  ordersCompleted: 'completed',
  ordersCancelled: 'cancelled',
  cart: 'cart',
  checkout: 'checkout',
};

export const ROUTES_MAP = {
  home: '/home',
  // shop related routes
  store: '/home/store',
  batteries: '/home/store/batteries',
  modules: '/home/store/modules',
  inverters: '/home/store/inverters',
  cables: '/home/store/cables',
  productCategory: '/home/store/:category',
  productDetail: '/home/store/:category/:id',
  cart: '/home/store/cart',
  checkout: '/home/store/checkout',
  checkoutSuccess: '/home/store/success',
  // my orders related routes
  myOrders: '/home/my-orders',
  myOrdersSearching: '/home/my-orders/searching',
  offersPending: '/home/my-orders/offers-pending',
  offersRequest: '/home/my-orders/offers-pending/request',
  orderInProgress: '/home/my-orders/order-in-progress',
  deliveryInProgress: '/home/my-orders/delivery-in-progress',
  ordersCompleted: '/home/my-orders/completed',
  // settings related routes
  settings: '/home/settings',
  deliveryInformation: '/home/settings/delivery-information',
  settingsAccount: '/home/settings/account',
  settingsNotifications: '/home/settings/notifications',
  // billing related routes
  billing: '/home/billing',
  // support related routes
  support: '/home/support',
  supportNewRequest: '/home/support/new-request',
  // auth related routes
  authLogin: '/auth/login',
  authCreatePassword: '/auth/create-password',
  authForgotPassword: '/auth/forgot-password',
  authResetPassword: '/auth/reset-password',
  authSetupPassword: '/auth/setup-password',
  authImpersonate: '/auth/admin',
  authMagicLink: '/auth/magic-login',
  authMagicLinkExpired: '/auth/magic-link-expired',
  // dev tools routes
  devTools: '/home/dev-tools',
};

export type RoutesMapValue<T> = T[keyof T];

export const EXTERNAL_ROUTES_MAP_ENUM = {
  generalTermsAndConditions: 'https://andercore.com/en/terms/',
  monduTermsAndConditions:
    'https://www.mondu.ai/m1-payment-postponement-agreement/',
  monduPrivacyPolicy: 'https://www.mondu.ai/gdpr-notification-for-buyers/',
};
