'use client';

import { useCallback, useEffect, useRef, useState } from 'react';
import Flex from '../flex/Flex';
import { twMerge } from 'tailwind-merge';

type TooltipProps = {
  children: React.ReactNode;
  message: string | React.ReactNode;
  className?: string;
  messageContainerClassName?: string;
};

const Tooltip = ({
  children,
  message,
  className,
  messageContainerClassName,
}: TooltipProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleToggle = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      setIsVisible(!isVisible);
    },
    [isVisible]
  );

  const handleClickOutside = useCallback((event: MouseEvent | TouchEvent) => {
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target as Node)
    ) {
      setIsVisible(false); // Close tooltip if clicked/touched outside
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside); // Desktop clicks
      document.addEventListener('touchstart', handleClickOutside); // Mobile touch events
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [handleClickOutside, isVisible]);

  return (
    <Flex
      className={twMerge(
        'relative inline-block text-left self-center',
        className
      )}
    >
      {/* Tooltip trigger element */}
      <Flex onClick={handleToggle} className="cursor-pointer justify-center">
        {children}
      </Flex>
      {/* Tooltip content */}
      {isVisible && (
        <div
          className={twMerge(
            'absolute z-90 w-auto min-w-40 p-2 text-white bg-black rounded top-8 left-[-100px] transform transition-opacity duration-300',
            messageContainerClassName
          )}
          ref={tooltipRef}
        >
          {message}
        </div>
      )}
    </Flex>
  );
};

export default Tooltip;
