import { twMerge } from 'tailwind-merge';

type TitleTooltipProps = {
  children: React.ReactNode;
  tooltipText: string | React.ReactNode;
  className?: string;
  divClassName?: string;
  arrowPlacement?: 'left';
};

/**
 * TitleTooltip component to display a tooltip on hover
 * @param children - the element on which the tooltip is to be displayed
 * @param tooltipText - the text or react node to be displayed in the tooltip
 * @returns
 */
const TitleTooltip = ({
  children,
  tooltipText,
  divClassName,
  className,
  arrowPlacement,
}: TitleTooltipProps) => {
  return (
    <div
      className={twMerge(
        'relative group inline-block cursor-pointer',
        divClassName
      )}
    >
      {children}
      <div
        className={twMerge(
          'absolute hidden md:group-hover:block bg-black text-white text-sm px-3 py-2 rounded shadow-lg z-100 w-max max-w-xs transform -translate-x-1/2 top-[-50px] left-1/2',
          className
        )}
      >
        {tooltipText}
        {/* Arrow at the bottom*/}
        <div
          className={twMerge(
            'absolute top-full left-1/2 transform -translate-x-1/2 translate-y-[-7px] w-3 h-3 bg-black rotate-45',
            arrowPlacement === 'left' &&
              'left-[-6px] top-1/2 transform -translate-y-1/2 translate-x-0'
          )}
        />
      </div>
    </div>
  );
};

export default TitleTooltip;
