import { twMerge } from 'tailwind-merge';
import Flex from '~/components/customComponents/flex/Flex';
import SwipeUpDown from '../swipe-up-down/SwipeUpDown';

type MobileNavPopupProps = {
  showMobileNavPopover: boolean;
  handleSwitchMobileNavPopover: () => void;
  children?: React.ReactNode;
  className?: string;
};
/**
 *    Renders the mobile navigation popover.
 * - It uses the `useI18n` hook to get the translation function.
 * - It returns a `Flex` component containing a `Text` component and a list of `navLinksMobileExtra` links.
 * - The `Flex` component has a dynamic class name based on the `showMobileNavPopover` prop.
 * @param showMobileNavPopover - A boolean value that determines whether the mobile navigation popover is shown.
 * @param handleSwitchMobileNavPopover - A function that handles the click event to switch the mobile navigation popover.
 */
const MobileNavPopup = ({
  showMobileNavPopover,
  handleSwitchMobileNavPopover,
  className,
  children,
}: MobileNavPopupProps) => {
  const popoverCustomClasses = twMerge('h-[80dvh]', className);
  return (
    <Flex
      className={twMerge(
        'px-4 flex-col transition-all duration-300 ease-in-out bg-white rounded-t-xl relative ',
        showMobileNavPopover ? popoverCustomClasses : 'h-0'
      )}
    >
      <SwipeUpDown
        handleToggle={handleSwitchMobileNavPopover}
        toggleTriggerVariable={showMobileNavPopover}
        className="overflow-auto"
      >
        {children}
      </SwipeUpDown>
    </Flex>
  );
};

export default MobileNavPopup;
