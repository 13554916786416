export enum FormItemTypeEnum {
  Phone = 'phone',
  TextArea = 'textarea',
  Counter = 'counter',
  HourPicker = 'hourPicker',
  RadioGroup = 'radioGroup',
  SimpleSelect = 'simpleSelect',
  Password = 'password',
  Text = 'text',
  Hidden = 'hidden',
  WeekPicker = 'weekPicker',
}
