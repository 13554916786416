'use client';

import { twMerge } from 'tailwind-merge';

type TextProps = {
  children: string | React.ReactNode;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

/**
 * Text component to display text content
 * - The text is displayed in the primary color with following
 * - font-primary (color: #3b3f44)
 * - font-normal (font-weight: 400)
 * - text-xs (font-size: 12px, line-height: 16px)
 * - md:text-sm (font-size: 14px, line-height: 20px)
 * - align-middle (vertical-align: middle)
 * @param { children, className}
 * @returns
 */
const Text = ({ children, className, ...props }: TextProps) => {
  return (
    <div
      className={twMerge(
        'text-primary font-normal text-xs md:text-sm align-middle',
        className
      )}
      data-testid="text"
      {...props}
    >
      {children}
    </div>
  );
};

export default Text;
